import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import msal from "vue-msal";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

// initialise the msal authentication
// TODO: need to make all these settings configuration driven between environments
Vue.use(msal, {
  auth: {
    clientId: process.env.VUE_APP__CLIENT_ID,
    authority: process.env.VUE_APP__AUTHORITY,
    requireAuthOnInitialize: false, // we handle this manually in our auth mechanics

    redirectUri:
      process.env.VUE_APP__BASEURL_ROOT + process.env.VUE_APP__BASEURL_PATH,

    postLogoutRedirectUri:
      process.env.VUE_APP__BASEURL_ROOT +
      process.env.VUE_APP__BASEURL_PATH +
      process.env.VUE_APP__SIGN_OUT_PAGE,

    validateAuthority: false,
  },
  request: {
    scopes: [process.env.VUE_APP__API_SCOPE],
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
