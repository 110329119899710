import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { _ } from "core-js";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REFRESH_ACCESS_TOKEN = "refreshAccessToken";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  isAuthenticated: !!JwtService.getToken(),
  accessToken: "",
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    console.log(`State authenticated: ${state.isAuthenticated}`);
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN]() {
    return new Promise((resolve) => {
      this._vm.$msal.signIn();
      resolve();
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    this._vm.$msal.signOut();
  },
  [VERIFY_AUTH](context) {
    console.log("Verifying authentication");

    let msalError = window.localStorage.getItem(
      `msal.${process.env.VUE_APP__CLIENT_ID}.error.description`
    );
    // let hasMsalError = msalError ? msalError.startsWith("AADB2C90077") : false;
    let hasCookieError = document.cookie.includes("AADB2C90077");

    if (msalError || hasCookieError) {
      // this detects the MSAL error and kicks off an interactive signIn
      console.log("signingin");
      context.commit(PURGE_AUTH);
      window.localStorage.clear();

      // purge all cookies for domain
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }

      var cookies2 = document.cookie.split("; ");
      console.log(JSON.stringify(cookies2));
      // window.location =
      //   process.env.VUE_APP__BASEURL_ROOT +
      //   "/" +
      //   process.env.VUE_APP__BASEURL_PATH;

      window.location.reload();
    }

    if (this._vm.$msal.isAuthenticated()) {
      if (this._vm.$msal.data.accessToken) {
        context.commit(SET_AUTH, this._vm.$msal.data.accessToken);
      }
    } else {
      console.log("trying relogin");
      return new Promise((resolve) => {
        this._vm.$msal.signIn();
        resolve();
      });
      context.commit(PURGE_AUTH);
    }
  },
  [REFRESH_ACCESS_TOKEN](context) {
    return new Promise((resolve) => {
      if (this._vm.$msal.isAuthenticated()) {
        // leaving this console logging to help debug authentication until we're confident in it
        // (MM 29/10/2020)
        console.log("refresh access token called");
        console.log(`token before: ${this._vm.$msal.data.accessToken}`);
        this._vm.$msal.acquireToken();
        console.log(`token after: ${this._vm.$msal.data.accessToken}`);
        context.commit(SET_AUTH, this._vm.$msal.data.accessToken);
      }
      resolve();
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, token) {
    console.log(`Access token: ${token}`);
    state.isAuthenticated = true;
    state.errors = {};
    state.accessToken = token;

    // manually save our access token to the service
    JwtService.saveToken(state.accessToken);

    // set the axios header
    ApiService.setHeader();
  },
  [PURGE_AUTH](state) {
    console.log("purging auth");
    state.isAuthenticated = false;
    state.accessToken = "";
    state.errors = {};

    // manually destroy token
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
