// action types

// mutation types
export const CLEAR_RECIPIENTS = "clearRecipients";
export const SET_RECIPIENTS = "setRecipients";
export const ADD_RECIPIENT = "addRecipient";
export const REMOVE_RECIPIENT = "removeRecipient";

export default {
  state: {
    recipients: [],
  },
  getters: {
    /**
     * Get list of recipients
     * @param state
     * @returns {*}
     */
    recipients(state) {
      return state.recipients;
    },
    recipientCaptions(state) {
      let result = state.recipients.map((x) => x.caption);
      return result;
    },
    recipientKeys(state) {
      let result = state.recipients.map((x) => x.key);
      return result;
    },
    recipientMobileNumbers(state) {
      let result = state.recipients.map((x) => x.mobileNumber);
      return result;
    },
  },
  actions: {},
  mutations: {
    [CLEAR_RECIPIENTS](state) {
      state.recipients = [];
    },
    [SET_RECIPIENTS](state, payload) {
      state.recipients = payload;
    },
    [ADD_RECIPIENT](state, payload) {
      state.recipients = [...state.recipients, payload];
    },
    [REMOVE_RECIPIENT](state, payload) {
      state.recipients = [
        ...state.recipients.filter((x) => x.caption != payload),
      ];
    },
  },
};
